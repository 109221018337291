@import '../common/styles/sass/variables';
@import '../common/styles/sass/mixins';

.projectsBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .projectsContainer {
    @include container();
    flex-direction: column;
    justify-content: flex-start;

    .projects {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 60px;
      width: 100%;

      @media (max-width: 992px) {
        justify-content: center;
        gap: 20px;
      }
    }
  }
}