@import '../common/styles/sass/variables';
@import '../common/styles/sass/mixins';

.mainBlock {
  display: flex;
  align-items: center;
  min-height: 100vh;

  .particles {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.4;
  }

  .greetingContainer {
    @include container();
    flex-wrap: nowrap;
    gap: 20px;

    .greeting {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include text('Poppins', 14px, 1.8);

      h1 {
        font-size: 50px;

        .name {
          position: relative;
          z-index: 0;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 20px;
            width: 100%;
            z-index: -1;
            background: $primaryColor;
          }
        }

        @media (max-width: 768px) {
          font-size: 40px;
        }
      }

      .profession {
        font-size: 27px;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
    }

    .photoContainer {
      position: relative;
      z-index: 1;

      .photo {
        position: relative;
        width: 400px;
        height: 500px;
        background-size: cover;
        background-position: center;
        z-index: 1;
        filter: saturate(40%);
        transition: 0.3s;

        &:hover {
          filter: none;
        }

        @media (max-width: 768px) {
          width: 250px;
          height: 350px;
        }
      }

      .border {
        position: absolute;
        display: block;
        left: 8%;
        top: -8%;
        height: 110%;
        width: 102%;
        border: 5px solid $primaryColor;
        z-index: -1;
      }

      @media (max-width: 992px) {
        margin-top: 40px;
      }

      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }


    @media (max-width: 992px) {
      flex-direction: column;
      flex-wrap: wrap;
      gap: 40px;
    }
  }
}

