@import './variables';

@mixin text($fontFamily: 'Josefin Sans', $fontSize: 16px, $lineHeight: 1.2) {
  font-family: $fontFamily, sans-serif;
  font-size: $fontSize;
  line-height: $lineHeight;
  color: $textColor;
}

@mixin flex($direction: row, $justifyContent: center, $alignItems: center, $wrap: wrap, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-wrap: $wrap;
  gap: $gap;
}

@mixin container() {
  @include flex($justifyContent: space-between, $gap: 60px);
  margin: 0 auto;
  width: 80%;
  height: 100%;

  @media (max-width: 768px) {
    width: 90%;
    justify-content: center;
  }
}

@mixin button($underlineHeight: 10px, $underlineWidth: 40%) {
  @include text('Poppins', 14px, 1.2);
  position: relative;
  align-self: center;
  padding: 7px 20px;
  width: max-content;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  z-index: 0;
  color: #ffffff;

  &:disabled, &:disabled::after, &:disabled:hover::after {
    opacity: 0.6;
    pointer-events: none;
    cursor: unset;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    height: $underlineHeight;
    width: $underlineWidth;
    background: $primaryColor;
    z-index: -1;
    transition: all 0.2s ease-in-out 0s;
  }

  &:hover::after {
    @media (min-width: 769px) {
      width: 100%;
      height: 110%;
    }
  }
}

@mixin bgSettings() {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}