@import '../common/styles/sass/mixins';

.skillsBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .skillsContainer {
    @include container();
    flex-direction: column;
    justify-content: flex-start;

    .title {
      position: relative;
      padding: 40px;
      @include text($fontSize: 26px);
      font-weight: 500;
      text-align: center;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        background: $primaryColor;
        width: 55px;
        height: 1px;
        transform: translate(-50%, 50%);
      }
    }

    .skills {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}





