@import '../common/styles/sass/variables';
@import '../common/styles/sass/mixins';

.contactsBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .contactsContainer {
    @include container();
    flex-direction: column;
    justify-content: flex-start;

    .form {
      display: flex;
      flex-direction: column;
      gap: 1.1rem;
      width: 100%;
      max-width: 800px;
      transition: 0.3s;
      opacity: 0;

      &.inView {
        opacity: 1;
      }

      & input, & textarea {
        display: block;
        padding: 15px;
        width: 100%;
        height: auto;
        @include text('Poppins', 14px, 1.5);
        background-color: $backgroundSecondColor;
        border: 1px solid #4a4a4a;
        outline: none;
        color: #a4a4a4;
        transition: box-shadow 0.3s;

        &:focus {
          border: 1px solid transparent;
          box-shadow: 0 0 2px 1px $primaryColor;
        }
      }

      & textarea {
        min-height: 160px;
        resize: vertical;
      }

      .error {
        display: block;
        margin-top: 4px;
        @include text('Poppins', 0, 1.5);
        color: #a60101;
        opacity: 0;
        transition: 0.3s;

        &.visible {
          font-size: 16px;
          opacity: 1;
        }
      }

      .button {
        display: flex;
        flex-wrap: nowrap;
        @include button($underlineHeight: 4px);
        margin: 0 auto;
        color: #ffffff;
        min-width: 0;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

  }
}