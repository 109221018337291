@import '../../common/styles/sass/variables';
@import '../../common/styles/sass/mixins';

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  max-width: 500px;
  list-style-type: none;
  opacity: 0;

  a {
    text-decoration: none;
  }

  &.inView {
    opacity: 1;
  }

  .coverImage {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 350px;
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    transition: 0.3s;
    background-color: $backgroundSecondColor;
    overflow: hidden;

    &:hover {
      background-size: auto 120%;

      & .description {
        opacity: 1;
        transform: none;
      }

      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(2px);
      }
    }

    .description {
      padding: 40px;
      @include text('Poppins', 16px, 1.8);
      text-decoration: none;
      z-index: 2;
      opacity: 0;
      transform: translateY(100px);
      transition: 0.4s;

      @media (max-width: 768px) {
        padding: 20px;
        font-size: 14px;
        opacity: 1;
        transform: none;
      }
    }

    .projectLink {
      padding: 10px;
      background-color: #252527;
      @include text('Poppins', 16px, 1.8);
      text-decoration: none;
      z-index: 1;
    }

    @media (max-width: 768px) {
      margin: 0 auto;
      width: 90%;
      height: 200px;
      background-size: auto 120%;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(2px);
      }
    }
  }

  .projectTitle {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 18px auto;
    @include button($underlineHeight: 4px);
    @include text($fontSize: 22px);
    text-transform: none;
    letter-spacing: 1px;
    text-decoration: none;

    &:hover {
      & * {
        color: $textColor;
      }
    }
  }

  .projectIcon {
    & * {
      width: 40px;
      height: 40px;
      color: #31aa52;
    }
  }
}


