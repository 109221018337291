@import '../common/styles/sass/mixins';

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  padding: 0;
  z-index: 10;
  transition: 400ms;
  backdrop-filter: blur(2px);

  .headerContainer {
    @include container();
    justify-content: flex-end;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    top: -100px;
    right: -100px;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    backdrop-filter: none;

    &.headerOpen {
      top: unset;
      right: unset;
      width: 100%;
      height: 100%;
      border-radius: unset;
    }
  }
}
