@import '../../common/styles/sass/mixins';

.burgerNav {
  @media (min-width: 769px) {
    display: none;
  }

  .burgerNavBtn {
    position: fixed;
    top: 40px;
    right: 20px;
    width: 2.3rem;
    height: 0.13rem;
    border-radius: 0;
    background-color: white;
    display: block;
    margin: 0;
    transition: 400ms;

    &:before {
      position: absolute;
      width: 1.3rem;
      height: 0.13rem;
      border-radius: 2px;
      background-color: white;
      display: block;
      margin: auto;
      transition: 400ms;
      content: '';
      backface-visibility: hidden;
      transform: rotate(0deg) translateY(10px);
      right: 0;
    }

    &:after {
      position: absolute;
      width: 2.3rem;
      height: 0.13rem;
      border-radius: 2px;
      background-color: white;
      display: block;
      margin: auto;
      transition: 400ms;
      content: '';
      backface-visibility: hidden;
      transform: rotate(0deg) translateY(-10px);
    }

    &.open {
      background-color: rgba(255, 255, 255, 0);

      &:before {
        transform: rotate(45deg) translateY(0px);
        width: 2.3rem;
      }

      &:after {
        transform: rotate(-45deg) translateY(0px);
      }
    }
  }

  .navList {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    list-style-type: none;

    li a {
      position: relative;
      text-transform: uppercase;
      text-decoration: none;
      @include button($underlineHeight: 4px);
      @include text($fontSize: 24px);
      white-space: nowrap;
      transition: all 0.2s ease-in-out 0s;

      &.active::after {
        width: 100%;
        height: 110%;
      }
    }
  }
}


