@import '../../styles/sass/variables';

.preloader {
  position: fixed;
  background: $backgroundColor;
  width: 100%;
  height: 100%;
  z-index: 20;

  .image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20%;
    transform: translate(-50%, -50%);
  }
}