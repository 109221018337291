@import '../common/styles/sass/mixins';

.cvBlock {
  @include flex();

  .cvContainer {
    @include container();
    flex-direction: column;
    align-items: center;

    .cvImage {
      width: 400px;
      border-radius: 10px;
      cursor: zoom-in;
      transition: 0.3s;

      @media (max-width: 576px) {
        width: calc(100vw / 2);
      }

      &.cvImageOpen {
        transform: scale(1.5);
      }
    }

    .button {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      gap: 6px;
      @include button($underlineHeight: 4px);
      margin: 18px auto 0 auto;
      min-width: 0;
      text-decoration: none;
    }
  }
}