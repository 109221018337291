@import './common/styles/sass/variables';

.App {
  > * {
    padding: 100px 0;
  }

  & > header:nth-child(1) {
    background-color: rgba(31, 31, 32, 0.9);
  }

  & > *:nth-child(odd) {
    background-color: $backgroundColor;
  }

  & > *:nth-child(even) {
    background-color: $backgroundSecondColor;
  }

  @media (max-width: 768px) {
    > * {
      padding: 80px 0;
    }
  }
}
