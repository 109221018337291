@import '../../common/styles/sass/mixins';

.navList {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  list-style-type: none;

  & li a {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    @include button($underlineHeight: 4px);
    @include text($fontSize: 18px);
    white-space: nowrap;
    transition: all 0.2s ease-in-out 0s;

    &.active {
      @include button($underlineHeight: 8px, $underlineWidth: 80%);
      @include text($fontSize: 24px);
    }

    @media (max-width: 768px) {
      font-size: 14px;
      padding: 5px;

      &.active {
        font-size: 18px;
        padding: 5px;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}


