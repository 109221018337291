@import '../../common/styles/sass/variables';

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: #ffffff;
  transition: all 0.2s ease-in-out 0s;

  & * {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: $primaryColor;
  }

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
}